import React from "react";
import { Link } from "react-router-dom";

const SingleShop = ({ image, name, title }) => {
  return (
    <>
      <div className="col-lg-3 col-md-6">
        <div className="product mb-40">
          <div className="product__img">
            <Link to="#">
              <img src={`/img/cell-marque/${image}`} alt="" />
            </Link>
            <div className="product-action text-center">
              <a href="#">
                <i className="fas fa-shopping-cart"></i>
              </a>
              <a href="#">
                <i className="fas fa-heart"></i>
              </a>
              <Link to="#">
                <i className="fas fa-expand"></i>
              </Link>
            </div>
          </div>
          <div className="product__content text-center pt-30">
            <h4 className="pro-title">
              <Link to="#">{title}</Link>
            </h4>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleShop;
