import React from "react";
import { useTranslation } from "react-i18next";
const ContactArea = () => {
  const { t } = useTranslation();
  const [contact, setContact] = React.useState(null);
  React.useEffect(() => {
    async function createMarkup() {
      let response;
      response = await fetch(
        `http://bios.ssdcreative.com/admin/api.php?topic=contact&lang=tr`
      );
      const backendHtmlString = await response.json();

      console.log(backendHtmlString);
      return backendHtmlString;
    }
    createMarkup().then((result) => setContact(result));
  }, []);
  return (
    <>
      <section
        className="contact-area pt-120 pb-90"
        data-background="assets/img/bg/bg-map.png"
      >
        <div className="container">
          <div className="row">
            <div className="col-xl-4 col-lg-4 col-md-4">
              <div className="contact text-center mb-30">
                <i className="fas fa-envelope"></i>
                <h3>{t("mailhere")}</h3>
                <p>{contact?.cmail}</p>
                <p>{contact?.mail2}</p>
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-4">
              <div className="contact text-center mb-30">
                <i className="fas fa-map-marker-alt"></i>
                <h3>{t("visithere")}</h3>
                <p>{contact?.address}</p>
              </div>
            </div>
            <div className="col-xl-4  col-lg-4 col-md-4 ">
              <div className="contact text-center mb-30">
                <i className="fas fa-phone"></i>
                <h3>{t("callhere")}</h3>
                <p>T: {contact?.tel}</p>
                <p>F: {contact?.fax}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactArea;
