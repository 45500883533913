import React from "react";
import SingleCount from "../../../components/SingleCount/SingleCount";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";
const HomeThreeAboutArea = () => {
  const { t } = useTranslation("usertable");
  const { i18n } = useTranslation("usertable");

  const [lang, setLang] = React.useState(i18n.language);
  const [html, setHTML] = React.useState({ __html: "" });
  React.useEffect(() => {
    i18n.on("languageChanged", (lng) => setLang(lng));
    async function createMarkup() {
      let response;
      response = await fetch(
        `http://bios.ssdcreative.com/admin/api.php?topic=aboutus&lang=${i18n.language}&tipo=html`
      );
      const backendHtmlString = await response.text();

      console.log(backendHtmlString);
      return { __html: backendHtmlString };
    }
    createMarkup().then((result) => setHTML(result));
  }, [lang]);
  return (
    <>
      <section className="about-area pt-115 pb-80">
        <div className="container">
          <div className="row align-items-center separator pb-110">
            <div className="col-xl-6 col-lg-6">
              <ScrollAnimation animateIn="fadeInUp" delay={300}>
                <div className="medical-icon-brand pos-rel f-left">
                  <img src="img/about/medi-brand.png" alt="" />
                </div>
                <div className="about-title mb-20 fix">
                  <h1 className="mb-40">{t("homeslogan")}</h1>
                  <h5 className="pink-color m-0">{t("homeaboutslogan")}</h5>
                </div>
              </ScrollAnimation>
            </div>
            <div className="col-xl-6 col-lg-6">
              <div
                className="about-right-content"
                dangerouslySetInnerHTML={html}
              ></div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HomeThreeAboutArea;
